import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';

@Component('its.check.module.checklists', TranslationAdditionalInformation.CID, {
    template: require('/app/modules/checklists/components/translations/TranslationAdditionalInformation.html'),
    controllerAs: 'vm',
    bindings: {
        originalFormLabel: '<',
        translatableLabel: '=',
    }
})
class TranslationAdditionalInformation {
    static IID:string = 'TranslationAdditionalInformation';
    static CID:string = 'itsTranslationAdditionalInformation';
    static $inject: Array<string> = ['APPCONFIG', 'iteLogger', '$sce'];

    static toolbarButtons: Array<string> = [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'fontSize',
        'fontFamily',
        'color',
        'outdent',
        'indent',
        'align',
        'formatOL',
        'formatUL',
        'insertLink',
        'html',
    ];

    public froalaOptions: any = {
        toolbarButtons: TranslationAdditionalInformation.toolbarButtons,
        toolbarButtonsMD: TranslationAdditionalInformation.toolbarButtons,
        toolbarButtonsSM: TranslationAdditionalInformation.toolbarButtons,
        toolbarButtonsXS: TranslationAdditionalInformation.toolbarButtons,
        linkInsertButtons: [],
        placeholderText: '',
        linkAlwaysBlank: true,
        linkAutoPrefix: 'http://',
        charCounterCount: false,
        quickInsertButtons: [],
        attribution: false,
        fontFamily: {
            'Arial,Helvetica,sans-serif': 'Arial',
            "'Arial Black',Helvetica,sans-serif": 'Arial Black',
            "'Comic Sans MS', cursive, sans-serif": 'Comic Sans MS',
            "'Courier New', Courier, monospace": 'Courier New',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Trebuchet MS', Helvetica, sans-serif": 'Trebuchet MS',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        },
        pluginsEnabled: ['url', 'colors', 'fontSize', 'fontFamily', 'lists', 'link', 'align', 'codeView'],
    };

    /**
     * @param config
     * @param {ILogger} logger
     * @param {angular.ISCEService} sce
     */
       constructor(
        private config: any,
        private logger: ILogger,
        protected sce: ng.ISCEService,
    ) {
        // set Froala License Key
        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }
    }

    /**
     * Trust the string as HTML and allow angular to render it
     *
     * @param {string} html
     * @returns {string}
     */
    public trustAsHtml(html: string): string {
        return this.sce.trustAsHtml(html);
    }

}
