import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { ISnapshotOriginator } from '@intouch/its.essential/app/essential/domain/snapshot/ISnapshotOriginator';
import { Snapshot } from '@intouch/its.essential/app/essential/domain/snapshot/Snapshot';
import { SnapshotManager } from '@intouch/its.essential/app/essential/domain/snapshot/SnapshotManager';
import { ChecklistService } from './ChecklistService';
import { Checklist, IChecklist } from '../domain/checklists/Checklist';
import { BehaviorSubject } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ItemInput } from '../domain/checklists/ItemInput';
import { IItemSection } from '../domain/checklists/ItemSection';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { ICheckApi } from '../api/CheckApi';
import { ChecklistUuidType } from '../domain/checklists/enums/ChecklistUuidType';

class ChecklistOriginator implements ISnapshotOriginator<Checklist> {
    private checklist: Checklist = null;

    public get currentValue(): Checklist {
        return this.checklist;
    }

    public createSnapshot(): Snapshot<Checklist> {
        return new Snapshot<Checklist>(this.checklist);
    }

    public setValue(checklist: Checklist): void {
        this.checklist = checklist;
    }

    public restoreFromSnapshot(surveyToRestoreTo: Snapshot<Checklist>): void {
        this.checklist = surveyToRestoreTo.getState();
    }
}

@Service('its.check.services', ChecklistEditService.IID, ChecklistEditService)
export class ChecklistEditService {
    static IID: string = 'itcChecklistEditService';
    static $inject: string[] = ['itcCheckApi', 'itcChecklistService', '$translate', '$mdDialog', '$state', '$q'];

    public editingItem: BehaviorSubject<ItemInput> = new BehaviorSubject<ItemInput>();
    public settingTab: string = 'basic';
    private snapshotManager: SnapshotManager<Checklist> = null;
    private checklistOriginator: ChecklistOriginator = new ChecklistOriginator();

    constructor(
        private checkApi: ICheckApi,
        private checklistService: ChecklistService,
        private translate: ng.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private state: angular.ui.IStateService,
        private q: ng.IQService
    ) {
        this.checklistService.checklistObserver.subscribe((checklist: Checklist) => {
            this.checklistOriginator.setValue(checklist);
            this.snapshotManager = new SnapshotManager<Checklist>(this.checklistOriginator);
        });
    }

    public saveSnapshot(): void {
        this.snapshotManager.saveSnapshot();
    }

    public restoreToPreviousSnapshot(): void {
        this.snapshotManager.restoreToPreviousSnapshot();
        this.checklistService.setChecklist(this.checklistOriginator.currentValue);
    }

    public editItemWithSnapshot(selectedItem: ItemInput, settingTab: string = 'basic'): void {
        this.saveSnapshot();
        this.editingItem.next(selectedItem);
        this.settingTab = settingTab
    }

    public getSettingsTab(): string {
        return this.settingTab;
    }

    public editItem(selectedItem: ItemInput): void {
        this.editingItem.next(selectedItem);
    }

    public cancelEdit(): void {
        this.restoreToPreviousSnapshot();
        this.clearEditingItem();
    }

    public clearEditingItem(): void {
        this.editingItem.next(null);
    }

    public saveChecklist(checklistToSave: IChecklist, currentSection?: IItemSection): ng.IPromise<IChecklist> {
        return this.checkApi
            .findChecklist(checklistToSave.originalUuid, ChecklistUuidType.Original)
            .then((latestChecklist: IChecklist) => {
                if (this.isLatestChecklist(checklistToSave, latestChecklist)) {
                    return this.checkApi.updateChecklist(checklistToSave);
                } else {
                    this.showIncompatibleVersionPrompt(latestChecklist, currentSection);
                    return this.q.reject();
                }
            });
    }

    /**
     * Handle the case in which we have a new draft of an original live version. If its a new version, we need to
     * transition as the old id will still be exposed in the URL
     *
     * @param newChecklist
     * @param section
     */
    public updateToLatestChecklistUrl(newChecklist: IChecklist, section: IItemSection = null): void {
        if (newChecklist.uuid !== this.checklistOriginator.currentValue.uuid) {
            this.setNewChecklistState(newChecklist, section);
        }
    }

    private setNewChecklistState(checklist: IChecklist, section: IItemSection, forceReload: boolean = false): void {
        this.state.go(
            this.state.current,
            {
                uuid: checklist.uuid,
                section: section,
            },
            {
                location: 'replace',
                reload: forceReload,
                notify: forceReload,
            }
        );
    }

    private showIncompatibleVersionPrompt(latestChecklist: IChecklist, currentSection: IItemSection): void {
        const confirmDialogSettings: any = {
            locals: {
                cancelText: this.translate.instant('GENERAL.CLOSE'),
                confirmText: this.translate.instant('GENERAL.RELOAD_NOW'),
                primaryButton: 'confirm',
                title: this.translate.instant('CHECKLISTS.CHANGES_MADE_TITLE'),
                description: this.translate.instant('CHECKLISTS.CHANGES_MADE_TEXT'),
            },
        };
        this.dialog.show(Confirm.instantiate(confirmDialogSettings)).then((confirmed: boolean) => {
            if (confirmed) {
                this.checklistService.setChecklist(latestChecklist);
                this.setNewChecklistState(latestChecklist, currentSection, true);
                this.clearEditingItem();
            }
        });
    }

    private isLatestChecklist(currentChecklist: IChecklist, latestChecklist: IChecklist): boolean {
        return (
            latestChecklist.updatedAt === currentChecklist.updatedAt &&
            latestChecklist.uuid === currentChecklist.uuid &&
            latestChecklist.originalUuid === currentChecklist.originalUuid
        );
    }
}
