import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { ICheckApi } from '../../../api/CheckApi';
import { Geo } from '@intouch/its.essential/app/essential/utils/Geo';
import { ScheduleSettings } from '../../../domain/checklists/SchedulerSettings';
import { ICheckSession } from '../../../services/CheckSession';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { IGroupListingItem } from '@intouch/its.essential/app/essential/domain/access/GroupListingItem';
import { IPager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IPageService } from '../../../services/PageService';
import { BaseChecklist } from '../BaseChecklist';
import { ChecklistService } from '../../../services/ChecklistService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import * as _ from 'lodash';
import { GroupDatalistModal } from '@intouch/its.essential/app/essential/modals/GroupDatalistModal';
import { SubscriptionPlanUtils } from '../../../utils/SubscriptionPlanUtils';
import { ChecklistEditService } from '../../../services/ChecklistEditService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';

/**
 * The checklist controller
 *
 * @param UserService
 * @param $state
 * @constructor
 */
@Controller(
    'its.check.module.checklists',
    ChecklistSchedulerSettingsController.IID,
    ChecklistSchedulerSettingsController
)
class ChecklistSchedulerSettingsController extends BaseChecklist {
    static IID: string = 'ChecklistSchedulerSettingsController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        'itcCheckApi',
        'iteToaster',
        'itcCheckSession',
        'iteAccessApi',
        'itcPageService',
        '$translate',
        'itcChecklistService',
        'iteAccessService',
        '$window',
        'itcChecklistEditService',
        '$scope',
    ];

    public formName: string = 'outcomeSettingsForm';
    public checklist: IChecklist;
    public subscriptionStatus: string = '';

    private weekDays: Array<{ label: string; value: string }> = [];
    private times: Array<{ label: string; value: string }> = [];
    private monthDays: Array<{ label: string; value: string }> = [];
    private months: Array<{ label: string; value: string }> = [];
    private cronMask: Array<string>;
    private enabled: boolean = false;
    private timeBefore: number;
    private timeBeforeFrame: string;
    private timeAfter: number;
    private timeAfterFrame: string;

    private defaultCronMask: string = '0 22 * * * *';
    private checklistSubscription: SubjectSubscription;

    constructor(
        protected state: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        protected checkApi: ICheckApi,
        protected toaster: IToaster,
        protected session: ICheckSession,
        private accessApi: IAccessApi,
        protected pageService: IPageService,
        protected translate: ng.translate.ITranslateService,
        protected checklistService: ChecklistService,
        protected accessService: IAccessService,
        private windowService: ng.IWindowService,
        protected checklistEditService: ChecklistEditService,
        private scope: ng.IScope
    ) {
        super(state, checkApi, toaster, pageService, translate, session, checklistService, checklistEditService);

        this.subscriptionStatus = this.accessService.getToken().getProductBySlug('check').subStatus;

        let checklist: IChecklist = this.checklistService.getChecklist();
        this.initSubscription();

        if (!checklist || checklist.uuid !== this.state.params['uuid']) {
            this.load().then(() => {
                this.setupScheduler();
            });
        } else {
            this.checklist = checklist.clone();
            this.setupScheduler();
        }
    }

    /**
     * Initializes the modal bound values based on the checklist and given mask
     *
     * @param mask
     */
    public init(mask: string): void {
        this.cronMask = mask.split(' ');
        this.timeBefore = ScheduleSettings.secondsToTime(this.checklist.scheduler.secondsBefore).value;
        this.timeBeforeFrame = ScheduleSettings.secondsToTime(this.checklist.scheduler.secondsBefore).type;
        this.timeAfter = ScheduleSettings.secondsToTime(this.checklist.scheduler.secondsAfter).value;
        this.timeAfterFrame = ScheduleSettings.secondsToTime(this.checklist.scheduler.secondsAfter).type;
    }

    /**
     * Will reset the modal to its empty state
     */
    public reset(): void {
        if (this.isTrial()) {
            this.enabled = false;
            return;
        }
        this.checklist.scheduler.cronMask = null;
        this.checklist.scheduler.timezone = null;
        this.checklist.scheduler.secondsBefore = 60 * 60 * 24;
        this.checklist.scheduler.secondsAfter = 0;
        this.checklist.scheduler.readyGroups = [];
        this.checklist.scheduler.overdueGroups = [];

        this.init(this.defaultCronMask);
    }

    public isTrial(): boolean {
        return this.accessService.isProductTrial();
    }

    /**
     * Opens the group info modal
     *
     * @param groupUuid
     */
    public openGroupInfoModal(groupUuid: string): void {
        this.dialog.show(GroupDatalistModal.instantiate({ locals: { groupUuid: groupUuid } }));
    }

    /**
     * Will add a new item to the cron mask (i.e. user click on a date/time button on the UI)
     *
     * @param value
     * @param section
     * @param defaultValue
     */
    public add(value: string, section: number, defaultValue: string = null): void {
        let cronMask: string = this.cronMask[section];
        let tempArray: Array<string> = cronMask.split(',');

        if (value === '*') {
            cronMask = '*';
        } else if (tempArray.indexOf(value) === -1) {
            if (tempArray.length === 1 && tempArray[0] === defaultValue) {
                tempArray.shift();
            }
            let index: number = tempArray.indexOf('*');
            if (index > -1) {
                tempArray.splice(index, 1);
            }

            tempArray.push(value);
            cronMask = tempArray.join(',');
        }

        this.cronMask[section] = cronMask;
    }

    /**
     * Will remove an item from the cron mask (i.e. user click on a date/time button on the UI)
     *
     * @param value
     * @param section
     * @param defaultIfEmpty
     */
    public remove(value: string, section: number, defaultIfEmpty: string = null): void {
        let cronMask: string = this.cronMask[section];
        let tempArray: Array<string> = cronMask.split(',');
        let index: number = tempArray.indexOf(value);

        if (index > -1 && value !== '*') {
            tempArray.splice(index, 1);
            if (tempArray.length === 0) {
                tempArray.push(defaultIfEmpty || '*');
            }
            cronMask = tempArray.join(',');
        }

        this.cronMask[section] = cronMask;
    }

    /**
     * Used to determine if a UI button is enabled or disabled (in the mask or not)
     *
     * @param value
     * @param section
     * @returns {boolean}
     */
    public isSelected(value: string, section: number): boolean {
        return this.cronMask[section].split(',').indexOf(value) > -1;
    }

    /**
     * Save checklist General Settings
     */
    public saveChecklist(): void {
        if (this.enabled) {
            this.checklist.scheduler.cronMask = this.cronMask.join(' ');

            if (this.timeBeforeFrame === 'days') {
                this.checklist.scheduler.secondsBefore = this.timeBefore * 60 * 60 * 24;
            } else if (this.timeBeforeFrame === 'hours') {
                this.checklist.scheduler.secondsBefore = this.timeBefore * 60 * 60;
            } else {
                this.checklist.scheduler.secondsBefore = this.timeBefore * 60;
            }

            if (this.timeAfterFrame === 'days') {
                this.checklist.scheduler.secondsAfter = this.timeAfter * 60 * 60 * 24;
            } else if (this.timeAfterFrame === 'hours') {
                this.checklist.scheduler.secondsAfter = this.timeAfter * 60 * 60;
            } else {
                this.checklist.scheduler.secondsAfter = this.timeAfter * 60;
            }
        } else {
            this.checklist.scheduler.cronMask = null;
        }

        this.saving = true;

        this.save(
            this.checklist,
            'GENERAL.MESSAGES.SAVED_CHANGES',
            'CHECKLISTS.ERRORS.UNABLE_TO_SAVE_RANDOMIZATION_SETTINGS',
            'home.checklists.settings.scheduler'
        ).finally(() => {
            this.saving = false;
        });
    }

    /**
     * Returns call to get access groups from the api
     *
     * @param {string} search
     * @param {IPager} pager
     * @return {angular.IPromise<PagedEntities>}
     */
    public findAccessGroups(search: string, pager: IPager): ng.IPromise<PagedEntities> {
        return this.accessApi.findGroups(search, pager);
    }

    /**
     * Sets up scheduler
     *
     */
    public setupScheduler(): void {
        if (this.checklist && this.checklist.scheduler) {
            this.enabled = this.checklist.scheduler.cronMask !== null;
            this.init(this.checklist.scheduler.cronMask || this.defaultCronMask);
            this.weekDays = ScheduleSettings.getWeekDays();
            this.times = ScheduleSettings.getTimes();
            this.monthDays = ScheduleSettings.getMonthDays();
            this.months = ScheduleSettings.getMonths();
        }
    }

    /**
     * Add selected ready group to scheduler settings
     *
     * @param {IGroupListingItem} group
     */
    public addSelectedReadyGroup(group: IGroupListingItem): void {
        if (!group) {
            return;
        }
        if (!this.checklist.scheduler.readyGroups) {
            this.checklist.scheduler.readyGroups = [];
        }

        if (!_.find(this.checklist.scheduler.readyGroups, { uuid: group.uuid })) {
            this.checklist.scheduler.readyGroups.push({ uuid: group.uuid, name: group.name });
        }
    }

    /**
     * Add selected overdue group to scheduler settings
     *
     * @param {IGroupListingItem} group
     */
    public addSelectedOverdueGroup(group: IGroupListingItem): void {
        if (!group) {
            return;
        }
        if (!this.checklist.scheduler.overdueGroups) {
            this.checklist.scheduler.overdueGroups = [];
        }

        if (!_.find(this.checklist.scheduler.overdueGroups, { uuid: group.uuid })) {
            this.checklist.scheduler.overdueGroups.push({ uuid: group.uuid, name: group.name });
        }
    }

    /**
     * Returns if subscription of check is trial
     *
     */
    public disableScheduler(): boolean {
        return (
            !this.subscriptionActive() ||
            SubscriptionPlanUtils.isCheckStandardPlan(this.subscriptionStatus) ||
            !this.accessService.hasFeatureEnabled('check', 'checklist-scheduler')
        );
    }

    /**
     * Determine if user has an active subscription
     *
     */
    public subscriptionActive(): boolean {
        return SubscriptionPlanUtils.isActive(this.subscriptionStatus);
    }

    /**
     * opens help
     */
    public openHelp(): void {
        this.windowService.open(
            'https://intouchcheck.zendesk.com/hc/en-us/articles/360011368174-Checklist-Scheduler-Settings',
            '_blank'
        );
    }

    private initSubscription(): void {
        this.checklistSubscription = this.checklistService.checklistObserver.subscribe((checklist) => {
            this.checklist = checklist;
        });
        this.scope.$on('$destroy', () => {
            if (this.checklistSubscription) {
                this.checklistSubscription.unsubscribe();
            }
        });
    }
}
